<template>
  <div>
    <hr />
    <div class="row" v-if="$parent.det_novedades.length > 0">
      <table class="table table-sm table-hover">
        <thead class="thead-dark">
          <tr class="text-center">
            <th scope="col">Nombre Sitio</th>
            <th scope="col">Sitio inicio</th>
            <th scope="col">Sitio final</th>
            <th scope="col">Mapa</th>
            <th scope="col">Fecha ini</th>
            <th scope="col">Fecha fin</th>
            <th scope="col">Tiempo (Min)</th>
            <th scope="col">Tipo</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in $parent.det_novedades"
            :key="item.id"
            :class="
              item.tipo_novedad == 4
                ? 'table-warning'
                : '' || item.tipo_novedad == 3
                ? 'table-danger'
                : '' || item.tipo_novedad == 5
                ? 'table-danger'
                : '' || item.tipo_novedad == 6
                ? 'table-danger'
                : '' || item.tipo_novedad == 7
                ? 'table-danger'
                : '' || item.tipo_novedad == 1
                ? 'table-danger'
                : ''
            "
          >
            <td class="text-center" v-if="item.sitio">
              {{ item.sitio.nombre }}
            </td>
            <td v-else></td>
            <td class="text-center">{{ item.lat_ini }} - {{ item.lon_ini }}</td>
            <td class="text-center">{{ item.lat_fin }} - {{ item.lon_fin }}</td>
            <td style="width: 50px" class="text-center">
              <button
                type="button"
                class="btn btn-sm bg-navy"
                data-toggle="modal"
                data-target="#modal-form-mapa-justificaciones"
                style="cursor: pointer"
                @click="llenarModalMapa(item)"
              >
                <i class="fa fa-map-marker-alt"></i>
              </button>
            </td>
            <td class="text-center">{{ item.fecha_ini }}</td>
            <td class="text-center">{{ item.fecha_fin }}</td>
            <td class="text-center">{{ item.duracion }}</td>
            <td class="text-center">{{ item.tipoNovedad }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row" v-else>
      <div class="alert alert-warning col-md-12">
        <h5><i class="icon fas fa-exclamation-triangle"></i> Atención!</h5>
        No se encontro novedad para este Viaje!
      </div>
    </div>
    <TifJustificacionesMapa ref="TifJustificacionesMapa" />
  </div>
</template>

<script>
import axios from "axios";
import TifJustificacionesMapa from "../justificaciones/TifJustificacionesMapa";
export default {
  name: "TifTurnoNovedad",
  components: {
    TifJustificacionesMapa,
  },
  data() {
    return {
      det_novedades: {},
    };
  },
  /*  validations: {
    API: {
      required,
    },
  },
 */
  methods: {
    llenarModalMapa(item, solicitud_id) {
      this.$refs.TifJustificacionesMapa.limpiar();
      this.$refs.TifJustificacionesMapa.llenar_modal_mapa(item, solicitud_id);
    },
  },

  mounted() {},
};
</script>
