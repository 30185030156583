<template>
  <div>
    <div
      class="modal fade"
      id="modal-form-detTurno"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      style="background-color: #00000082; z-index: 10000"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-xl p-0 pt-1" role="document">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <div
              class="modal-title text-white"
              style="font-size: 10px; text-transform: uppercase"
              v-if="turno.vehiculo"
            >
              <strong>DETALLE DEL TURNO:</strong> {{ turno.id }} -
              <strong>FECHA INICIO:</strong> {{ turno.fecha_ini }} -
              <strong>FECHA FIN:</strong> {{ turno.fecha_fin }} -
              <strong>PLACA:</strong> {{ turno.vehiculo.placa }} -
              <strong>BLOQUE:</strong> {{ turno.bloque.nombre }} -
              <strong>EMPRESA:</strong> {{ turno.empresa.razon_social }} -
              <strong>OPERACIÓN:</strong> {{ turno.operacion.Noperacion }} -
              <strong>PRODUCTO:</strong>
              {{ turno.producto.nombre }}
            </div>
            <button
              type="button"
              class="close text-white"
              @click="limpiarModal()"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-InfoTurno"
                  data-toggle="tab"
                  href="#TurnosInfoTurno"
                  >Información Turno</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-PuntosVisita"
                  @click="getDatosPuntos()"
                  data-toggle="tab"
                  href="#PuntosVisita"
                  >Puntos Visita</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-TifRecorridos"
                  @click="getRecorrido()"
                  data-toggle="tab"
                  href="#TifRecorridos"
                  >Recorridos</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-TifNovedades"
                  @click="getNovedades()"
                  data-toggle="tab"
                  href="#TifNovedades"
                  >Novedades</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-TifAutorizaciones"
                  @click="getAutorizaciones()"
                  data-toggle="tab"
                  href="#TifAutorizaciones"
                  >Autorizaciones</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-TifOdometro"
                  data-toggle="tab"
                  @click="getOdometros()"
                  href="#TifOdometro"
                  >Odómetros / Kms</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Mapa"
                  @click="getDatosMapa()"
                  data-toggle="tab"
                  href="#Mapa"
                  >Mapa</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-TifPozosSatelites"
                  @click="getPozosSatelites()"
                  data-toggle="tab"
                  href="#TifPozosSatelites"
                  v-if="turno.producto_id == 38"
                  >Viajes Pozos Satelites</a
                >
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" id="TurnosInfoTurno">
                <TifTurnoInfoTurno
                  v-if="
                    turno_id != null && Object.keys(turno).length > 0 && accion
                  "
                  ref="TifTurnoInfoTurno"
                />
              </div>
              <div class="tab-pane" id="PuntosVisita">
                <TifTurnoInfoPuntos
                  v-if="turno_id != null && accion == 2"
                  ref="TifTurnoInfoPuntos"
                />
              </div>
              <div class="tab-pane" id="TifRecorridos">
                <TifTurnoRecorrido
                  v-if="turno_id != null && accion == 3"
                  ref="TifTurnoRecorrido"
                />
              </div>
              <div class="tab-pane" id="TifNovedades">
                <TifTurnoNovedad
                  v-if="turno_id != null && accion == 4"
                  ref="TifTurnoNovedad"
                />
              </div>
              <div class="tab-pane" id="TifAutorizaciones">
                <TifTurnoAutorizaciones
                  v-if="turno_id != null && accion == 5"
                  ref="TifTurnoAutorizaciones"
                />
              </div>
              <div class="tab-pane" id="Mapa">
                <TifTurnoMapa
                  v-if="turno_id != null && accion == 6"
                  ref="TifTurnoMapa"
                />
              </div>
              <div class="tab-pane" id="TifPozosSatelites">
                <TifTurnoPozosSatelites
                  v-if="turno_id != null && accion == 7"
                  ref="TifTurnoPozosSatelites"
                />
              </div>
              <div class="tab-pane" id="TifOdometro">
                <TifTurnoOdometro
                  v-if="turno_id != null && accion == 8"
                  ref="TifTurnoOdometro"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
import $ from "jquery";
import TifTurnoInfoTurno from "./TifTurnoInfoTurno";
import TifTurnoInfoPuntos from "./TifTurnoInfoPuntos";
import TifTurnoMapa from "./TifTurnoMapa";
import TifTurnoRecorrido from "./TifTurnoRecorrido";
import TifTurnoNovedad from "./TifTurnoNovedad";
import TifTurnoAutorizaciones from "./TifTurnoAutorizaciones";
import TifTurnoPozosSatelites from "./TifTurnoPozosSatelites";
import TifTurnoOdometro from "./TifTurnoOdometro";

export default {
  name: "TifTurnoDet",
  components: {
    TifTurnoInfoTurno,
    TifTurnoInfoPuntos,
    TifTurnoRecorrido,
    TifTurnoMapa,
    TifTurnoNovedad,
    TifTurnoAutorizaciones,
    TifTurnoPozosSatelites,
    TifTurnoOdometro,
  },

  data() {
    return {
      accion: 1,
      turno_id: null,
      turno: {},
      det_puntos: {},
      det_recorrido: {},
      det_novedades: {},
      det_autorizaciones: {},
      det_mapa: [],
      listasForms: {},
      det_pozosSatelites: {},
      odometros: {},
    };
  },
  validations: {
    form: {},
  },

  methods: {
    async llenar_modal_detTurno(turno) {
      this.$parent.cargando = true;
      await this.getDatosTurno(turno, 1);
      $(".nav-link").removeClass("active");
      $("#tab-InfoTurno").addClass("active");
      $(".tab-pane").removeClass("active");
      $("#TurnosInfoTurno").addClass("active");
      this.$parent.cargando = false;
    },

    async getDatosTurno(turno, accion) {
      this.accion = accion;
      this.turno_id = turno;
      this.turno = {};
      await axios
        .get("/api/tif/turnos/ver_detalle_turno", {
          params: {
            turno_id: turno,
            accion: accion,
          },
        })
        .then((response) => {
          this.turno = response.data;
          if (this.turno.det_tarifa) {
            /* Tarifas Tiempos */
            let valorDisp = this.turno.det_tarifa.valor_disp;

            this.turno.valorDisp = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              valorDisp
            );

            let valorOp = this.turno.det_tarifa.valor_op;

            this.turno.valorOp = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              valorOp
            );

            let costoOpera =
              (this.turno.det_tarifa.valor_op * this.turno.tiempo_operativo) /
              60 /
              this.turno.turno.dia_turno_one.cant_horas;

            this.turno.costoOpera = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              costoOpera
            );

            let costoDispo =
              (this.turno.det_tarifa.valor_disp *
                this.turno.tiempo_disponible) /
              60 /
              this.turno.turno.dia_turno_one.cant_horas;

            this.turno.costoDispo = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              costoDispo
            );

            let valorTotal = costoOpera + costoDispo;

            this.turno.valorTotal = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              valorTotal
            );

            /* Tarifas F + V */
            let fijoTarifa = this.turno.det_tarifa.costo_fijo;

            this.turno.fijoTarifa = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              fijoTarifa
            );

            let fijoVariable = this.turno.det_tarifa.costo_variable;

            this.turno.fijoVariable = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              fijoVariable
            );

            let costoFijo =
              (this.turno.det_tarifa.costo_fijo * this.turno.tiempo_operativo) /
              60 /
              this.turno.turno.dia_turno_one.cant_horas;

            this.turno.costoFijo = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              costoFijo
            );

            let costoVariable =
              this.turno.det_tarifa.costo_variable * this.turno.km_final;

            this.turno.costoVariable = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              costoVariable
            );

            let valorTotalF = costoVariable + costoFijo;

            this.turno.valorTotalF = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              valorTotalF
            );
          }
        });
    },

    getDatosPuntos() {
      this.accion = 2;
      this.det_puntos = {};
      axios
        .get("/api/tif/turnos/ver_detalle_turno", {
          params: {
            turno_id: this.turno_id,
            accion: this.accion,
          },
        })
        .then((response) => {
          this.det_puntos = response.data;
        });
    },

    getRecorrido() {
      this.accion = 3;
      this.det_recorrido = {};
      axios
        .get("/api/tif/turnos/ver_detalle_turno", {
          params: {
            turno_id: this.turno_id,
            accion: this.accion,
          },
        })
        .then((response) => {
          this.det_recorrido = response.data;
        });
    },

    getNovedades() {
      this.accion = 4;
      this.det_novedades = {};
      axios
        .get("/api/tif/turnos/ver_detalle_turno", {
          params: {
            turno_id: this.turno_id,
            accion: this.accion,
          },
        })
        .then((response) => {
          this.det_novedades = response.data;
        });
    },

    getAutorizaciones() {
      this.accion = 5;
      this.det_autorizaciones = {};
      axios
        .get("/api/tif/turnos/ver_detalle_turno", {
          params: {
            turno_id: this.turno_id,
            accion: this.accion,
          },
        })
        .then((response) => {
          this.det_autorizaciones = response.data;
        });
    },

    limpiarModal() {
      this.turno = {};
      this.turno_id = null;
    },

    getDatosMapa() {
      this.accion = 6;
      this.det_mapa = [];
      axios
        .get("/api/tif/turnos/ver_detalle_turno", {
          params: {
            turno_id: this.turno_id,
            accion: this.accion,
          },
        })
        .then((response) => {
          this.det_mapa = response.data;
        });
    },

    getPozosSatelites() {
      this.accion = 7;
      this.det_pozosSatelites = {};
      axios
        .get("/api/tif/turnos/ver_detalle_turno", {
          params: {
            turno_id: this.turno_id,
            accion: this.accion,
          },
        })
        .then((response) => {
          this.det_pozosSatelites = response.data;
        });
    },

    getOdometros() {
      this.accion = 8;
      this.odometros = {};
      axios
        .get("/api/tif/turnos/ver_detalle_turno", {
          params: {
            turno_id: this.turno_id,
            accion: this.accion,
          },
        })
        .then((response) => {
          this.odometros = response.data;
        });
    },

    formatCurrency(locales, currency, fractionDigits, number) {
      var formato = new Intl.NumberFormat(locales, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: fractionDigits,
      }).format(number);

      return formato;
    },
  },

  mounted() {},
};
</script>
