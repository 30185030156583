<template>
  <div>
    <div class="row" v-if="$parent.det_pozosSatelites.length > 0">
      <table class="table table-sm table-hover">
        <thead class="thead-dark">
          <tr class="text-center">
            <th scope="col">Punto Inicio</th>
            <th scope="col">Fecha Inicio</th>
            <th scope="col">Punto Final</th>
            <th scope="col">Fecha Final</th>
            <th scope="col">Tiempo (Min)</th>
            <th scope="col">KM</th>
            <th scope="col">Tarifa</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in $parent.det_pozosSatelites" :key="item.id">
            <td class="text-center">{{ item.origen.nombre }}</td>
            <td class="text-center">{{ item.fecha_ini }}</td>
            <td class="text-center">{{ item.destino.nombre }}</td>
            <td class="text-center">{{ item.fecha_fin }}</td>
            <td class="text-center">{{ item.duracion }}</td>
            <td class="text-center">{{ item.km }}</td>
            <td class="text-center">{{ item.tarifa_km.descripcion }} -> ${{ item.tarifa_km.valor_tarifa }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row" v-else>
      <div class="alert alert-warning col-md-12">
        <h5><i class="icon fas fa-exclamation-triangle"></i> Atención!</h5>
        No se encontro registro este Viaje!
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "TifTurnoPozosSatelites",
  components: {},
  data() {
    return {
      det_pozosSatelites: {},
    };
  },

  methods: {
  },

  mounted() {},
};
</script>
