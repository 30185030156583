<template>
  <div v-if="$parent.det_mapa.length > 0">
    <br />
    <div class="row">
      <div class="btn-group float-right pl-2">
        <label class="badge bg-frontera text-white mr-2 align-top">
          <input
            type="checkbox"
            v-model="form.traza"
            @change="showDraggable()"
          />
          <i style="font-size: 22px" class="fas fa-route p-1"></i>
        </label>
        <label class="badge bg-frontera text-white mr-2 align-top">
          <input
            type="checkbox"
            id="geocerca"
            v-model="form.geocercas"
            @change="showGeocercas()"
          />
          <i style="font-size: 22px" class="fas fa-map-marked-alt p-1"></i>
        </label>
        <div class="btn-group float-right pl-2">
          <button
            type="button"
            class="btn btn-success btn-lg"
            @click="generarListadoExcel()"
          >
            <i class="far fa-file-excel"></i>
          </button>
        </div>
      </div>
    </div>
    <hr />
    <div class="container-fluid">
      <div class="card">
        <div class="card-header pt-2 pb-2">
          <div class="row">
            <div
              class="col-md-12"
              v-if="
                this.$parent.turno.bloque.ciudad.latitud &&
                  this.$parent.turno.bloque.ciudad.longitud
              "
            >
              <gmap-map
                :center="center"
                :zoom="zoom"
                :tilt="tilt"
                :heading="heading"
                style="width: 100%; height: 650px"
              >
                <gmap-marker
                  :key="index"
                  v-for="(m, index) in markersTraza"
                  :position="m.position"
                  :icon="m.icon"
                  :animation="2"
                  @click="toggleInfoWindow(m, index, m.info)"
                ></gmap-marker>
                <gmap-info-window
                  :options="infoOptions"
                  :position="infoWindowPos"
                  :opened="infoWinOpen"
                  @closeclick="infoWinOpen = false"
                >
                  <div v-html="infoContent"></div>
                </gmap-info-window>
                <gmap-polygon
                  v-for="poligono in poligonos"
                  :options="{ fillColor: poligono.color }"
                  :key="poligono.id"
                  :paths="poligono.path"
                  :editable="false"
                  :draggable="false"
                  @click="
                    toggleInfoWindowPoli(poligono, poligono.id, poligono.info)
                  "
                />
                <gmap-circle
                  v-for="circular in circunferencias"
                  :options="{ fillColor: circular.color }"
                  :key="circular.id"
                  :center="circular.center"
                  :radius="circular.radius"
                  :info="circular.info"
                  @click="
                    toggleInfoWindowCir(circular, circular.id, circular.info)
                  "
                ></gmap-circle>
              </gmap-map>
            </div>
            <div class="col-md-12" v-else>
              <gmap-map
                :center="center2"
                :zoom="zoom"
                :tilt="tilt"
                :heading="heading"
                style="width: 100%; height: 650px"
              >
                <gmap-marker
                  :key="index"
                  v-for="(m, index) in markersTraza"
                  :position="m.position"
                  :icon="m.icon"
                  :animation="2"
                  @click="toggleInfoWindow(m, index, m.info)"
                ></gmap-marker>
                <gmap-info-window
                  :options="infoOptions"
                  :position="infoWindowPos"
                  :opened="infoWinOpen"
                  @closeclick="infoWinOpen = false"
                >
                  <div v-html="infoContent"></div>
                </gmap-info-window>
                <gmap-polygon
                  v-for="poligono in poligonos"
                  :options="{ fillColor: poligono.color }"
                  :key="poligono.id"
                  :paths="poligono.path"
                  :editable="false"
                  :draggable="false"
                  @click="
                    toggleInfoWindowPoli(poligono, poligono.id, poligono.info)
                  "
                />
                <gmap-circle
                  v-for="circular in circunferencias"
                  :options="{ fillColor: circular.color }"
                  :key="circular.id"
                  :center="circular.center"
                  :radius="circular.radius"
                  :info="circular.info"
                  @click="
                    toggleInfoWindowCir(circular, circular.id, circular.info)
                  "
                ></gmap-circle>
              </gmap-map>
            </div>
            <div
              class="col-md-5"
              id="traza"
              style="display: none; position: absolute"
            >
              <div
                class="card card-frontera card-outline collapsed-header"
                id="trazaHeader"
              >
                <div class="card-header pt-0 pb-0" id="headingOne">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <i class="fas fa-route text-success"></i>
                      <b>&nbsp;Administrar Traza</b>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseOne"
                  class="collapse show"
                  aria-labelledby="trazaHeader"
                  data-parent="#traza"
                >
                  <div class="card-body">
                    <div class="row">
                      <div class="form-group col-md-6">
                        <label>Cantidad</label>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          placeholder="Cantidad Puntos"
                          v-model="filter.cantidad"
                          style="font-size: 12px; background-color: #fff"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <br />
                        <div class="btn-group float-right pt-1" role="group">
                          <button
                            type="button"
                            class="btn btn-success"
                            id="atras"
                            :disabled="!markersTraza[1]"
                            @click="backPoint()"
                          >
                            <i class="fas fa-angle-double-left"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-success"
                            id="adelante"
                            :disabled="markersTraza.length > puntosTraza.length"
                            @click="nextPoint()"
                          >
                            <i class="fas fa-angle-double-right"></i>
                          </button>
                        </div>
                      </div>
                      <div class="form-group col-md-12">
                        <div class="card card-outline card-navy">
                          <div class="card-header pt-2 pb-2">
                            <h3 class="card-title">
                              <h6>
                                <i class="fas fa-truck"></i>
                              </h6>
                            </h3>
                            <div class="card-tools">
                              <button
                                type="button"
                                class="btn btn-tool"
                                data-card-widget="collapse"
                                data-toggle="tooltip"
                                title="Collapse"
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                            </div>
                            <div v-html="html"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import "jquery-ui-dist/jquery-ui";
import $ from "jquery";
export default {
  name: "Mapas",

  data() {
    return {
      infoContent: "",
      filter: {},
      tilt: 45,
      heading: 90,
      zoom: 8,
      html: null,
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      form: {
        geocercas: false,
        traza: false,
      },
      center: {
        lat: parseFloat(this.$parent.turno.bloque.ciudad.latitud),
        lng: parseFloat(this.$parent.turno.bloque.ciudad.longitud),
      },
      center2: { lat: 4.651371, lng: -74.071509 },
      markers: [],
      markersTraza: [],
      places: [],
      currentPlace: null,
      locations: {},
      poligonos: [],
      circunferencias: [],
      puntoInicialLat: null,
      puntoInicialLng: null,
      puntoFinalLat: null,
      puntoFinalLng: null,
      infoPuntoFinal: "",
      infoPuntoInicial: "",
      puntofin: null,
      vehiculo_id: null,
      latitud: null,
      longitud: null,
      puntosTraza: [],
      path: [],
    };
  },

  methods: {
    async showDraggable() {
      this.puntosTraza = [];
      this.markers = [];
      if (await this.form.traza) {
        this.puntosTraza = this.$parent.det_mapa;
        await this.trazaTurno();
        $("#traza").css("display", "block");
        $("#traza").draggable();
      } else {
        $("#traza").css("display", "none");
      }
    },

    trazaTurno() {
      if (this.puntosTraza.length > 0) {
        const marker = {
          lat: parseFloat(this.puntosTraza[0].latitud),
          lng: parseFloat(this.puntosTraza[0].longitud),
        };
        this.markersTraza.push({
          id: this.puntosTraza[0].id,
          position: marker,
          icon: "/img/icon_punto_g_ok.png",
          info: this.puntosTraza[0].ventana,
        });
        this.center = marker;
        this.zoom = 10;
      } else {
        this.$parent.$refs.closeModal.click();
        this.$swal({
          customClass: {
            container: "my-swal",
          },
          title: "Tramas no encontradas!",
          text: "No se encontraron tramas para este viaje",
          icon: "warning",
        });
      }
    },

    async showGeocercas() {
      if (await this.form.geocercas) {
        this.cargando = true;
        await axios
          .get("/api/tif/mapas/showGeocercas", {
            params: { bloque_id: this.$parent.turno.bloque_id },
          })
          .then((response) => {
            this.cargando = false;
            this.locations = response.data.gps_fin;
            this.locations.geocerca_poli.forEach((geocerca) => {
              if (geocerca.puntos.length > 0) {
                let puntos = [];
                geocerca.puntos.forEach((punto) => {
                  puntos.unshift({
                    lng: parseFloat(punto.longitud),
                    lat: parseFloat(punto.latitud),
                  });
                });
                this.poligonos.unshift({
                  id: geocerca.id,
                  name: geocerca.desc,
                  path: puntos,
                  info: geocerca.ventana,
                  color: geocerca.color,
                });
              }
            });
            for (var j = 0; j < this.locations.geocerca_cir.length; j++) {
              if (this.locations.geocerca_cir[j]["lat"] !== "") {
                this.circunferencias.unshift({
                  center: {
                    lat: parseFloat(this.locations.geocerca_cir[j]["lat"]),
                    lng: parseFloat(this.locations.geocerca_cir[j]["lon"]),
                  },
                  radius: parseFloat(this.locations.geocerca_cir[j]["radio"]),
                  info: this.locations.geocerca_cir[j]["ventana"],
                  color: this.locations.geocerca_cir[j]["color"],
                });
              }
            }
          })
          .catch(function(error) {
            this.cargando = false;
          });
      } else {
        this.locations = {};
        this.circunferencias = [];
        this.poligonos = [];
      }
    },

    generarListadoExcel() {
      let turno_id = this.$parent.turno_id;
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/tif/mapas/exportTifTraza",
        data: { turno_id },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    nextPoint() {
      let dist =
        parseInt(this.markersTraza.length - 1) + parseInt(this.filter.cantidad);
      for (let i = this.markersTraza.length - 1; i < dist; i++) {
        if (this.markersTraza.length < this.puntosTraza.length) {
          const marker = {
            lat: parseFloat(this.puntosTraza[i].latitud),
            lng: parseFloat(this.puntosTraza[i].longitud),
          };
          this.markersTraza.push({
            id: this.puntosTraza[i].id,
            position: marker,
            info: this.puntosTraza[i].ventana,
            icon: "/img/icon_viaje.png",
          });
          this.center = marker;
          this.zoom = 10;
          this.html = this.markersTraza[this.markersTraza.length - 1].info;
        } else {
          const marker = {
            lat: parseFloat(this.puntosTraza[i].latitud),
            lng: parseFloat(this.puntosTraza[i].longitud),
          };
          this.markersTraza.push({
            id: this.puntosTraza[i].id,
            position: marker,
            info: this.puntosTraza[i].ventana,
            icon: "/img/icon_punto_g.png",
          });
          this.$swal({
            customClass: {
              container: "my-swal",
            },
            title: "Fin del recorrido!",
            text: "No se encontraron más tramas para este viaje",
            icon: "info",
          });

          break;
        }
      }
    },

    backPoint() {
      let dist =
        parseInt(this.markersTraza.length - 1) - parseInt(this.filter.cantidad);
      for (let i = this.markersTraza.length - 1; i > dist; i--) {
        this.markersTraza.splice(i, 1);
        this.html = this.markersTraza[this.markersTraza.length - 1].info;
      }
    },

    toggleInfoWindow: function(marker, idx, ventana) {
      this.infoWindowPos = marker.position;
      this.infoContent = ventana;
      this.center = marker.position;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    toggleInfoWindowPoli: function(poli, idx, ventana) {
      this.infoWindowPos = poli.path[0];
      this.infoContent = ventana;
      this.center = poli.path[0];

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    toggleInfoWindowCir: function(cir, idx, ventana) {
      this.infoWindowPos = cir.center;
      this.infoContent = ventana;
      this.center = cir.center;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
  },
};
</script>
<style>
.my-swal {
  z-index: 20000 !important;
}
</style>
