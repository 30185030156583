<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Turnos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tif</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Consultas</li>
                  <li class="breadcrumb-item active">Turnos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card" style="display: inherit">
              <div>
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-md-1">
                      <label>N° Turno</label>
                      <input
                        type="number"
                        v-model="filtros.id"
                        placeholder="N° Turno"
                        label="id"
                        class="form-control form-control-sm"
                        @keyup.enter="getIndex()"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <label>Vehiculo</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Placa"
                        v-model="filtros.vehiculo_id"
                      />
                    </div>

                    <div class="form-group col-md-2">
                      <label>Remolque</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Placa"
                        v-model="filtros.remolque_id"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label>Conductor</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="conductor"
                        label="nombre"
                        class="form-control form-control-sm p-0"
                        :filterable="false"
                        :options="listasForms.conductores"
                        @search="buscarConductor"
                        @input="getIndex()"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-3">
                      <label>Conductor 2</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="conductor2"
                        label="nombre"
                        class="form-control form-control-sm p-0"
                        :filterable="false"
                        :options="listasForms.conductores2"
                        @search="buscarConductor2"
                        @input="getIndex()"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-4">
                      <label>Empresa</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="empresa"
                        placeholder="Razon social"
                        label="razon_social"
                        :options="listasForms.empresas"
                        @input="seleccionarTransportadora()"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-2">
                      <label>Tipo Tarifa</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="filtros.tipo_tarifa"
                        @change="getIndex()"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="tarifa in listasForms.tipo_tarifas"
                          :key="tarifa.numeracion"
                          :value="tarifa.numeracion"
                        >
                          {{ tarifa.descripcion }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-2">
                      <label>Bloque</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="bloque"
                        placeholder="Bloques"
                        label="nombre"
                        :options="listasForms.bloques"
                        @input="getSelectBloques()"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-2" v-if="bloque">
                      <label>Tipo Operacion</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="operacion"
                        placeholder="Tipo Operacion"
                        label="Noperacion"
                        :options="listasForms.operaciones"
                        @input="getSelectTipoOperacion()"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-2">
                      <label>Producto</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="producto"
                        placeholder="Nombre"
                        label="nombre"
                        :options="listasForms.productos"
                        @input="seleccionarProducto()"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-2">
                      <label>Ruta</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="ruta"
                        placeholder="Nombre"
                        label="nombre"
                        class="form-control form-control-sm p-0"
                        :filterable="false"
                        :options="listasForms.rutas"
                        @search="buscarRutas"
                        @input="getIndex()"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-2">
                      <label>Estado</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="filtros.estado"
                        @change="getIndex()"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="estado in listasForms.estados"
                          :key="estado.numeracion"
                          :value="estado.numeracion"
                        >
                          {{ estado.descripcion }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-4">
                      <label>Rango de fechas del viaje</label>
                      <div class="row">
                        <div class="col-md-6">
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="filtros.fecha_inicio"
                            @input="validaFechas()"
                          />
                        </div>
                        <div class="col-md-6">
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="filtros.fecha_fin"
                            @input="validaFechas()"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1">
                      <div class="btn-group float-right">
                        <button
                          type="button"
                          class="btn bg-navy mt-4"
                          @click="getIndex()"
                        >
                          <i class="fas fa-search"></i><br />Buscar
                        </button>
                      </div>
                    </div>
                    <div class="col-md-1">
                      <div class="btn-group float-right">
                        <button
                          type="button"
                          class="btn btn-danger mt-4"
                          @click="limpiar()"
                        >
                          <i class="fas fa-broom"></i><br />Limpiar
                        </button>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="btn-group float-right">
                        <button
                          type="button"
                          class="btn btn-primary btn-md"
                          v-if="$store.getters.can('tif.turnos.create')"
                          @click="direccionar()"
                        >
                          <i class="fas fa-plus"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-success"
                          data-toggle="modal"
                          data-target="#modal_form_turno_export"
                          v-if="$store.getters.can('tif.turno.export')"
                        >
                          <i class="far fa-file-excel"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <div class="card-body table-responsive p-0">
                      <div class="row">
                        <div class="col-md-12">
                          <table
                            class="
                              table
                              table-bordered
                              table-striped
                              table-hover
                              table-sm
                            "
                            style="font-size: 0.8em"
                          >
                            <thead class="thead">
                              <tr>
                                <th class="text-center">#</th>
                                <th class="text-center">Vehículo</th>
                                <th class="text-center">Remolque</th>
                                <th class="text-center">Conductor</th>
                                <th class="text-center">Conductor2</th>
                                <th class="text-center">Tipo Operación</th>
                                <th class="text-center">Tipo Tarifa</th>
                                <th class="text-center">Bloque</th>
                                <th class="text-center">Transportadora</th>
                                <th class="text-center">Ruta</th>
                                <th class="text-center">Producto</th>
                                <th class="text-center">Fecha Ini</th>
                                <th class="text-center">Fecha Fin</th>
                                <th class="text-center">Km Recorrido</th>
                                <th class="text-center">Tipo Inicio</th>
                                <th class="text-center">Estado</th>
                                <th class="text-center">Inspección</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="item in turnos.data" :key="item.id">
                                <td>
                                  <button
                                    type="button"
                                    class="btn btn-sm btn-default py-0"
                                    data-toggle="modal"
                                    data-target="#modal-form-detTurno"
                                    @click="llenarModalDetTurno(item.id)"
                                  >
                                    {{ item.id }}
                                  </button>
                                </td>

                                <td>
                                  {{ item.vehiculo.placa }} -
                                  {{ item.vehiculo.tipo_vehiculo.nombre }}
                                </td>

                                <td v-if="item.remolque != null">
                                  {{ item.remolque.placa }}
                                </td>
                                <td v-else>
                                  <span class="badge badge-danger"
                                    >Sin Asignar</span
                                  >
                                </td>
                                <td>
                                  {{ item.conductor.nombres }}
                                  {{ item.conductor.apellidos }}
                                  <span class="badge badge-info">
                                    {{ item.conductor.numero_documento }}
                                  </span>
                                </td>
                                <td v-if="item.conductor2 !== null">
                                  {{ item.conductor2.nombres }}
                                  {{ item.conductor2.apellidos }}
                                  <span class="badge badge-info">
                                    {{ item.conductor2.numero_documento }}
                                  </span>
                                </td>
                                <td v-else>
                                  <span class="badge badge-danger"
                                    >Sin Asignar</span
                                  >
                                </td>
                                <td>
                                  {{ item.operacion.Noperacion }}
                                </td>
                                <td>{{ item.tipoTarifa }}</td>
                                <td>
                                  {{ item.bloque.nombre }}
                                </td>
                                <td>
                                  {{ item.empresa.razon_social }}
                                </td>
                                <td>
                                  {{ item.ruta.nombre }}
                                </td>
                                <td v-if="item.producto">
                                  {{ item.producto.nombre }}
                                </td>
                                <td v-else></td>
                                <td>
                                  {{ item.fecha_ini }}
                                </td>
                                <td>
                                  {{ item.fecha_fin }}
                                </td>
                                <td class="text-center" v-if="item.km_final">
                                  {{ item.km_final }}
                                </td>
                                <td v-else>
                                  <span class="badge badge-danger"
                                    >Sin Asignar</span
                                  >
                                </td>
                                <td class="text-center">
                                  <span
                                    class="badge alert-default-primary"
                                    v-if="item.tipo_inicio == 1"
                                    >Automatico</span
                                  >
                                  <span
                                    class="badge alert-default-success"
                                    v-else
                                    >Manual</span
                                  >
                                </td>
                                <td class="text-center">
                                  <span
                                    class="badge"
                                    :class="
                                      item.estado == 1
                                        ? 'badge-success'
                                        : item.estado == 2 ||
                                          item.estado == 3 ||
                                          item.estado == 4
                                        ? 'badge-info'
                                        : item.estado == 5
                                        ? 'badge-warning'
                                        : item.estado == 6 || item.estado == 11
                                        ? 'badge-danger'
                                        : item.estado == 7
                                        ? 'badge-primary'
                                        : item.estado == 8
                                        ? 'badge-dark'
                                        : item.estado == 9
                                        ? 'bg-green'
                                        : item.estado == 10
                                        ? 'badge-light'
                                        : ''
                                    "
                                  >
                                    {{ item.estado }}. {{ item.estadoTurno }}
                                  </span>
                                </td>
                                <td
                                  class="text-center"
                                  v-if="item.vehiculo.tif_inspeccion.length > 0"
                                >
                                  <button
                                    type="button"
                                    class="btn btn-sm bg-danger"
                                    @click="
                                      verPDF(item.vehiculo.tif_inspeccion[0].id)
                                    "
                                  >
                                    <i class="fas fa-file-pdf"></i>
                                  </button>
                                </td>
                                <td v-else></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="float-left" v-if="turnos.total">
                    <p>
                      Mostrando del <b>{{ turnos.from }}</b> al
                      <b>{{ turnos.to }}</b> de un total:
                      <b>{{ turnos.total }}</b> Registros
                    </p>
                  </div>
                  <div class="float-left" v-else>
                    <p>
                      <span class="badge badge-danger">
                        No hay registros para mostrar
                      </span>
                    </p>
                  </div>
                  <pagination
                    :data="turnos"
                    @pagination-change-page="getIndex"
                    :limit="5"
                    align="right"
                  ></pagination>
                </div>
              </div>
            </div>
          </div>
        </section>
        <TifTurnoDet ref="TifTurnoDet" />
        <TifTurnoExport ref="TifTurnoExport" />
      </div>
    </div>
  </div>
</template>
<script>
import pagination from "laravel-vue-pagination";
import TifTurnoDet from "../turnos/TifTurnoDet";
import vSelect from "vue-select";
import axios from "axios";
import Loading from "../../../../components/Loading";
import TifTurnoExport from "../turnos/TifTurnoExport";
export default {
  name: "TifTurnoIndex",
  components: {
    Loading,
    pagination,
    TifTurnoDet,
    vSelect,
    TifTurnoExport,
  },
  data() {
    return {
      cargando: false,
      turnos: {},
      filtros: {},
      bloque: null,
      operacion: null,
      conductor: null,
      conductor2: null,
      vehiculo: null,
      remolque: null,
      empresa: null,
      ruta: null,
      producto: null,
      listasForms: {
        estados: [],
        conductores: [],
        conductores2: [],
        productos: [],
        rutas: [],
        cabezotes: [],
        remolques: [],
        empresas: [],
        operaciones: [],
        tipo_tarifas: [],
        bloques: [],
        campo: [],
      },
    };
  },
  methods: {
    getIndex(page = 1) {
      if (this.conductor != null) {
        this.filtros.conductor_id = this.conductor.id;
      }

      if (this.conductor2 != null) {
        this.filtros.conductor_id_2 = this.conductor2.id;
      }

      if (this.ruta != null) {
        this.filtros.ruta_id = this.ruta.id;
      }

      this.cargando = true;
      axios
        .get("/api/tif/turnos?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.turnos = response.data;          
          this.cargando = false;
        });
    },

    validaFechas() {
      const fecha_menor = new Date(this.filtros.fecha_inicio);
      const fecha_mayor = new Date(this.filtros.fecha_fin);
      // Se valida que la fecha inicial sea menor que la fecha final
      if (fecha_menor > fecha_mayor) {
        this.filtros.fecha_fin = null;
        this.$swal({
          icon: "error",
          title: `La fecha inicio no puede ser mayor a la fecha fin...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    limpiar() {
      this.filtros.id = "";
      this.filtros.vehiculo_id = "";
      this.filtros.remolque_id = "";
      this.filtros.conductor_id = "";
      this.filtros.conductor_id_2 = "";
      this.filtros.producto_id = "";
      this.filtros.empresa_id = "";
      this.filtros.ruta_id = "";
      this.filtros.estado = "";
      this.filtros.fecha_inicio = "";
      this.filtros.fecha_fin = "";
      this.filtros.bloque_id = "";
      this.filtros.tipo_tarifa = "";
      this.conductor = null;
      this.conductor2 = null;
      this.vehiculo = null;
      this.remolque = null;
      this.empresa = null;
      this.ruta = null;
      this.producto = null;
      this.operacion = null;
      this.bloque = null;
      this.getIndex();
    },

    getOperacion() {
      axios
        .get("/api/tif/operaciones/lista", {
          params: {
            bloque_id: this.filtros.bloque_id,
          },
        })
        .then((response) => {
          this.listasForms.operaciones = response.data;
        });
    },

    getTipoTarifa() {
      axios.get("/api/lista/96").then((response) => {
        this.listasForms.tipo_tarifas = response.data;
      });
    },

    getBloques() {
      axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },

    buscarCabezotes() {
      let me = this;
      var url = "api/admin/vehiculos/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.cabezotes = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarRemolques() {
      let me = this;
      var url = "api/admin/remolques/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.remolques = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarRutas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/tif/rutas/lista?nombre=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.rutas = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarProductos() {
      let me = this;
      axios
        .get("api/admin/productos/lista", {
          params: {
            linea_negocio_id: [3],
          },
        })
        .then(function(response) {
          me.listasForms.productos = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarConductor(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/conductores/lista?numero_documento=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.conductores = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error - " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarConductor2(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/conductores/lista?numero_documento=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.conductores2 = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error - " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarTransportadora() {
      let me = this;
      var url = "api/admin/empresas/lista?estado=1";
      axios
        .get(url, {
          params: {
            linea_negocio_id: [3],
          },
        })
        .then(function(response) {
          me.listasForms.empresas = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    seleccionarVehiculo() {
      if (this.vehiculo != null) {
        this.filtros.vehiculo_id = this.vehiculo.id;
      } else {
        this.filtros.vehiculo_id = null;
      }
    },

    seleccionarRemolque() {
      if (this.remolque != null) {
        this.filtros.remolque_id = this.remolque.id;
      } else {
        this.filtros.remolque_id = null;
      }
    },

    seleccionarTransportadora() {
      if (this.empresa != null) {
        this.filtros.empresa_id = this.empresa.id;
      } else {
        this.filtros.empresa_id = null;
      }
    },

    seleccionarProducto() {
      if (this.producto != null) {
        this.filtros.producto_id = this.producto.id;
      } else {
        this.filtros.producto_id = null;
      }
    },

    getSelectBloques() {
      if (this.bloque != null) {
        this.filtros.bloque_id = this.bloque.id;
        this.getOperacion();
      } else {
        this.filtros.bloque_id = null;
        this.operacion = null;
        this.listasForms.operaciones = [];
      }
    },

    getSelectTipoOperacion() {
      if (this.operacion != null) {
        this.filtros.operacion_id = this.operacion.id;
      } else {
        this.filtros.operacion_id = null;
      }
    },

    direccionar(item = null) {
      let accion = "Crear";
      if (item) {
        accion = "Editar";
      }
      return this.$router.push({
        name: "/Tif/TurnosForm",
        params: {
          accion,
          item,
        },
      });
    },

    verPDF(inspeccion_id) {
      this.cargando = true;
      axios({
        method: "get",
        url: "/api/tif/inspecciones/pdf/" + inspeccion_id,
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    llenarModalDetTurno(turno) {
      this.$refs.TifTurnoDet.llenar_modal_detTurno(turno);
    },

    getEstados() {
      axios.get("/api/lista/103").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
  },

  mounted() {
    this.getIndex();
    this.getEstados();
    this.getTipoTarifa();
    this.getBloques();
    this.buscarCabezotes();
    this.buscarRemolques();
    this.buscarTransportadora();
    this.buscarRutas();
    this.buscarProductos();
  },
};
</script>
