<template>
  <div>
    <!-- Modal mapa-->
    <div
      class="modal fade"
      id="modal-form-mapa-justificaciones"
      style="background-color: #00000082; z-index: 10000"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Mapa ubicación</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"              
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <gmap-map
                  :center="center"
                  :zoom="10"
                  style="width: 100%; height: 400px"
                  ref="map"
                >
                  <gmap-marker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position.sync="m.position"
                    :icon="m.icon"
                    :clickable="true"
                    :draggable="false"
                    @click="
                      (center = m.position),
                        toggleInfoWindow(m.position, m.id, m.info)
                    "
                  ></gmap-marker>
                  <gmap-info-window
                    :options="infoOptions"
                    :position="infoWindowPos"
                    :opened="infoWinOpen"
                    @closeclick="infoWinOpen = false"
                  >
                    <div v-html="infoContent"></div>
                  </gmap-info-window>
                  <gmap-polygon
                    v-for="poligono in poligonos"
                    :options="{ fillColor: poligono.color }"
                    :key="poligono.id"
                    :paths="poligono.path"
                    :editable="false"
                    :draggable="false"
                    @click="
                      toggleInfoWindowPoli(poligono, poligono.id, poligono.info)
                    "
                  />
                  <gmap-circle
                    v-for="circular in circunferencias"
                    :options="{ fillColor: circular.color }"
                    :key="circular.id"
                    :center="circular.center"
                    :radius="circular.radius"
                    :info="circular.info"
                    @click="
                      toggleInfoWindowCir(circular, circular.id, circular.info)
                    "
                  ></gmap-circle>
                </gmap-map>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "TifJustificacionesMapa",

  data() {
    return {
      novedad: null,
      solicitud_id: null,
      puntoInicialLat: null,
      puntoInicialLng: null,
      puntoFinalLat: null,
      puntoFinalLng: null,
      infoPuntoFinal: "",
      infoPuntoInicial: "",
      puntofin: null,
      vehiculo_id: null,
      latitud: null,
      longitud: null,
      puntosTraza: [],
      markers: [],
      locations: {},
      poligonos: [],
      circunferencias: [],
      zoom: 9,
      path: [],
      currentPlace: null,
      center: {
        lat: 3.780728474,
        lng: -71.62614209,
      },
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    };
  },

  methods: {
    toggleInfoWindow: function(position, idx, ventana) {
      this.infoWindowPos = position;
      this.infoContent = ventana;

      //compruebe si es el mismo marcador que se seleccionó en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si es un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    toggleInfoWindowPoli: function(poli, idx, ventana) {
      this.infoWindowPos = poli.path[0];
      this.infoContent = ventana;
      this.center = poli.path[0];

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    toggleInfoWindowCir: function(cir, idx, ventana) {
      this.infoWindowPos = cir.center;
      this.infoContent = ventana;
      this.center = cir.center;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    limpiar() {
      this.markers = [];
      this.novedad = {};
      this.solicitud_id = null;
    },

    llenar_modal_mapa(item, solicitud_id) {
      this.showGeocercas(item);
      this.solicitud_id = solicitud_id;
      this.novedad = item;
      this.markers = [
        {
          position: {
            lat: parseFloat(this.novedad.lat_ini),
            lng: parseFloat(this.novedad.lon_ini),
          },
          info: "Punto Inicio Novedad",
          icon: "/img/icon_punto_g.png",
        },
        {
          position: {
            lat: parseFloat(this.novedad.lat_fin),
            lng: parseFloat(this.novedad.lon_fin),
          },
          info: "Punto Fin Novedad",
          icon: "/img/icon_punto_g_ok.png",
        },
      ];
      const marker1 = {
        lat: parseFloat(this.novedad.lat_ini),
        lng: parseFloat(this.novedad.lon_ini),
      };
      this.center = marker1;
    },

    async showGeocercas(item) {
      this.cargando = true;
      await axios
        .get("/api/tif/mapas/showGeocercas", {
          params: { bloque_id: item.tif_turno.bloque_id },
        })
        .then((response) => {
          this.cargando = false;
          this.locations = response.data.gps_fin;
          this.locations.geocerca_poli.forEach((geocerca) => {
            if (geocerca.puntos.length > 0) {
              let puntos = [];
              geocerca.puntos.forEach((punto) => {
                puntos.unshift({
                  lng: parseFloat(punto.longitud),
                  lat: parseFloat(punto.latitud),
                });
              });
              this.poligonos.unshift({
                id: geocerca.id,
                name: geocerca.desc,
                path: puntos,
                info: geocerca.ventana,
                color: geocerca.color,
              });
            }
          });
          for (var j = 0; j < this.locations.geocerca_cir.length; j++) {
            if (this.locations.geocerca_cir[j]["lat"] !== "") {
              this.circunferencias.unshift({
                center: {
                  lat: parseFloat(this.locations.geocerca_cir[j]["lat"]),
                  lng: parseFloat(this.locations.geocerca_cir[j]["lon"]),
                },
                radius: parseFloat(this.locations.geocerca_cir[j]["radio"]),
                info: this.locations.geocerca_cir[j]["ventana"],
                color: this.locations.geocerca_cir[j]["color"],
              });
            }
          }
        })
        .catch(function(error) {
          this.cargando = false;
        });
    },
  },

  mounted() {},
};
</script>
