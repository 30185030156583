var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('hr'),(_vm.$parent.det_novedades.length > 0)?_c('div',{staticClass:"row"},[_c('table',{staticClass:"table table-sm table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.$parent.det_novedades),function(item){return _c('tr',{key:item.id,class:item.tipo_novedad == 4
              ? 'table-warning'
              : '' || item.tipo_novedad == 3
              ? 'table-danger'
              : '' || item.tipo_novedad == 5
              ? 'table-danger'
              : '' || item.tipo_novedad == 6
              ? 'table-danger'
              : '' || item.tipo_novedad == 7
              ? 'table-danger'
              : '' || item.tipo_novedad == 1
              ? 'table-danger'
              : ''},[(item.sitio)?_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.sitio.nombre)+" ")]):_c('td'),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.lat_ini)+" - "+_vm._s(item.lon_ini))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.lat_fin)+" - "+_vm._s(item.lon_fin))]),_c('td',{staticClass:"text-center",staticStyle:{"width":"50px"}},[_c('button',{staticClass:"btn btn-sm bg-navy",staticStyle:{"cursor":"pointer"},attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-mapa-justificaciones"},on:{"click":function($event){return _vm.llenarModalMapa(item)}}},[_c('i',{staticClass:"fa fa-map-marker-alt"})])]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.fecha_ini))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.fecha_fin))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.duracion))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.tipoNovedad))])])}),0)])]):_c('div',{staticClass:"row"},[_vm._m(1)]),_c('TifJustificacionesMapa',{ref:"TifJustificacionesMapa"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-dark"},[_c('tr',{staticClass:"text-center"},[_c('th',{attrs:{"scope":"col"}},[_vm._v("Nombre Sitio")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Sitio inicio")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Sitio final")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Mapa")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Fecha ini")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Fecha fin")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Tiempo (Min)")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Tipo")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert alert-warning col-md-12"},[_c('h5',[_c('i',{staticClass:"icon fas fa-exclamation-triangle"}),_vm._v(" Atención!")]),_vm._v(" No se encontro novedad para este Viaje! ")])
}]

export { render, staticRenderFns }